import {LitElement, html } from 'lit';
import {customElement} from 'lit/decorators.js';

@customElement('foot-note')
export class NavBar extends LitElement {

    constructor() {
        super();
      }
    
    override render() {
        const style = html 
        `
          <style>@import "https://cdnjs.cloudflare.com/ajax/libs/flowbite/2.3.0/flowbite.min.css"</style>
          <style>@import "styles.css</style>
        `;

        return html 
        `
            ${style}
            <footer id="footer" class="bg-green-vogue-500 rounded-lg shadow m-4 dark:bg-gray-800 w-100 text-white text-center text-xs">
                <div class="mx-auto max-w-screen-xl p-4 w-full">
                    © 2024 <a href="https://vortexsolutions.ae/" class="hover:underline">Vortex Solutions</a>. All Rights Reserved.
                </div>
            </footer>
        `
    }
}